@import 'variables';

body {
  margin: 0;
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  display: flex;
  justify-content: center;
}

p {
  margin: 0;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
$base-color: purple;

:export {
  baseColor: $base-color;
}

.title.-main {
  font-weight: 600;
  color: var(--primary-text);
  margin: 0 0 14px;
}

.text .text-link {
  text-decoration: none;
  border-bottom: dotted;
  border-width: thin;
  color: #066bd6;
  cursor: pointer;
}

.carousel-initialized {
  padding: 0 !important;
  height: 300px;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Regular.ttf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Bold.ttf') format('opentype');
  font-weight: 600;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/OpenSans-Regular.ttf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/OpenSans-SemiBold.ttf') format('opentype');
  font-weight: 600;
}
