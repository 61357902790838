@media (min-width:1440px){.szcnqm9{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;margin-top:40px;width:100%;}}
.s47mqxx{box-sizing:border-box;width:300px;border:1px solid rgba(0,203,144,0.24);border-radius:4px;}
.c1qah3gi{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;width:70%;margin-left:35px;}
.tdx2vp9{font-family:Open Sans,sans-serif;font-weight:600;color:var(--primary-text);margin:var(--tdx2vp9-0);padding:var(--tdx2vp9-1);}
.ssyj2z1{font-family:Open Sans,sans-serif;font-weight:600;color:var(--grey-text);margin:var(--ssyj2z1-0);padding:var(--ssyj2z1-1);}
.t18n11cv{font-family:Open Sans,sans-serif;font-weight:400;color:var(--grey-text);margin:var(--t18n11cv-0);padding:var(--t18n11cv-1);}
.nhqrhc4{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;margin-top:20px;}.nhqrhc4 .icon.-hand{width:32px;height:28px;margin-right:12px;}.nhqrhc4 > .notice{font-size:12px;line-height:20px;width:calc(100% - 44px);}.nhqrhc4 > .notice > .subtitle{display:block;color:var(--primary-text);font-weight:600;}.nhqrhc4 > .notice > .text{font-weight:400;color:var(--grey-text);}.nhqrhc4 > .notice > .date{display:inline-block;font-weight:600;color:var(--grey-text);}@media (min-width:768px){.nhqrhc4{margin-top:24px;}}@media (min-width:1024px){.nhqrhc4{border-top:1px solid #c4c4c4;padding-top:25px;}}
.i1ichn5j{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}.i1ichn5j .popup .MuiButton-label{font-size:12px;line-height:20px;}.i1ichn5j .icon.-insurance{width:35px;height:20px;margin-right:9px;fill:#066bd6;padding-left:7px;box-sizing:border-box;}.i1ichn5j > .notice{font-size:12px;line-height:20px;width:calc(100% - 44px);}.i1ichn5j > .notice > .subtitle{display:block;color:var(--primary-text);font-weight:600;}.i1ichn5j > .notice > .text{font-weight:400;color:var(--grey-text);}.i1ichn5j > .notice > .company{display:block;color:#066bd6;font-weight:600;}@media (min-width:768px){.i1ichn5j > .notice > .company{display:inline-block;}}
.edktywh{margin-top:5px;color:red;width:100%;}
.p1v5u99j{color:#5d5d5d;font-size:16px;font-weight:600;line-height:24px;}
.azv4d59{margin-top:13px;width:100%;}
.omrr0hr.omrr0hr{box-sizing:border-box;height:46px;width:48px;border:1px solid #8d9bb5;border-radius:4px;background-color:#ffffff;}
