.inputContainer {
  display: flex;
  width: 300px;
}

.tooltip {
  position: relative;
}

.tooltip input {
  padding: 20px 15px 12px 20px;
}

.tooltip > .text {
  position: absolute;
  top: 5px;
  left: 10px;
  color: #bbbbbc;

  font-weight: 400;
  font-size: 10px;
}

.separator {
  margin: 0 4px;
  padding: 0;
  height: 2px;
  width: 16px;
  align-self: center;
  background: #303030;
}

.range {
  width: 290px !important;
}

.inputWrapper {
  padding: 0 0 40px 0;
}

.inputWrapper .MuiSlider-thumb {
  width: 20px;
  height: 20px;
  background-color: #fff;
  border: 1px solid #066bd6;
  bottom: 10px;
}
